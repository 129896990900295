import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import {
  SD_divider,
  sd_minus_icon,
  sd_plus_icon,
  user12,
  user2,
  user3,
  user4,
  user5,
  user6,
  userborder,
  Temple_history,
  Saswatha,
  svtrust,
  intellectual,
  chairman,
  executive,
  user7,
  user9,
  user13,
  user15,
  SD_gallery_08,
  SD_Logo_circle,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import Sd_loading from "../../sd-common-components/sd-loading";
import Sd_comming_soon from "../../../pages/sd-about/sd-coming-soon";

const Sd_about_general_information_component = ({
  match,
  visible,
  history,
}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [type, setType] = useState(match.params.type);
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);
  const [drop5, setDrop5] = useState(false);
  const [drop6, setDrop6] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const titleRef = useRef();
  const temples = [
    {
      name: "Sri Rukmini Satyabhama Sametha Venugopala Swamy Temple - Hamsaladeevi",
    },
    { name: "Sri Rukmini Satyabhama Sametha Venugopala Swamy Temple - Movva" },
    { name: "Sri Siddehdra Yogi Mandiram - Kuchipudi" },
    { name: "Sri Venkateswara Swamy Temple - Godugupet, Machilipatnam" },
    { name: "Sri Venkateswara Swamy Temple - Nimmakuru" },
    { name: "Sri Brahmaramba Mallikharjuna Swamy Temple - Peddaogirala" },
    { name: "Sri Gowri sametha Kedareswara Swamy Temple - Seetharampuram" },
    { name: "Sri Kodanda Rama Swamy Temple - Gudavalli" },
    { name: "Sri Rama Lingeswara Swamy Temple - Gudavalli" },
    { name: "Sri Seetharama Swamy Temple - Tadigadapa, Vijayawada Rural" },
    { name: "Sri Seetharamaa Swamy Temple - Enikepadu, Vijayawada Rural" },
    {
      name: "Sri Vasantha Venu Gopala & Anjeneya Swamy Temple - Poranki, Vijayawada",
    },
    {
      name: "Sri Vasantha Mallikharjuna Swamy Temple - Brahmin Street, Vijayawada",
    },
    {
      name: "Sri Prathyaksha Saneeswara Swamy Temple - Seethammavari Paadalu, Vijayawada",
    },
    { name: "Sri Venu Gopala Swamy Temple - Kothapeta, Vijayawada" },
    { name: "Sri Visweswara Swamy Temple - Gollapudi, Vijayawada" },
    { name: "Sri Venu Gopala Swamy Temple -Gollapudi, Vijayawada" },
    { name: "Sri Seetharama Swamy Temple - Pydurupadu, Vijayawada Rural" },
    {
      name: "Sri Santhaana Venu Gopala Swamy & Anjaneya Swamy Temple - Kowluru, G.Konduru Mandal",
    },
    { name: "Sri Tripuranthakeswara Temple - Ibrahimpatnam" },
    { name: "Sri Anjaneya Swamy Temple - Kotikalapudi (V), Ibrahimpatnam (M)" },
    { name: "Sri Pattabhi Ramaswamy Temple- Indrakeeladri, Vijayawada" },
    { name: "Sri Laxminarayana Swamy Mandir - Gulabchand Street, Vijayawada" },
    { name: "Sri Rajarajeswara Swamy Temple - Nidamanur, Vijayawada" },
    { name: "Sri Venugopala Swamy Temple - Vellaturu, G.Konduru (M)" },
    {
      name: "Sri PanduRanga Vittaleswara & Bhavani SankaraSwamy TempleKothuru - Tadepalli (V)",
    },
    {
      name: "Sri Sri Sobhanadri Lakshmi Nrusimha Swamy Varla Veda Sastra Patasala - Agiripalli (V)",
    },
    {
      name: "Sri Challam Raju Venkata Seshaiah Choultry - Samarangam Chowk, Vijayawada",
    },
    {
      name: "Sri Ramalingeswara Swamy Temple - Polakampadu (V), Tadepalli (M)",
    },
    { name: "Sri Pattabhi Rama Swamy Temple - Kolanukonda (V), Tadepalli(M)" },
    { name: "Sri Avadhoota Arogyasadanam Trust - Patamata, Vijayawada" },
    { name: "Sri Avadhoota Tapovanam Trust - Patamata, Vijayawada" },
    {
      name: "Sri Maddi Veeranjaneya Kodandaraama Swamy Temple - Seetanagaram, Vijayawada",
    },
  ];
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (match?.params?.type) {
      setType(match?.params?.type);
    } else {
      setType("sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple");
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  useEffect(() => {
    setImageLoading(true);

    if (data[type]?.image === "") {
      setImageLoading(false);
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [type]);

  useEffect(() => {
    history?.push(`/${selectedLanguage}/about/general-information/${type}`);
  }, [type]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  const data = {
    "visiting-places": {
      heading: `Visiting Places`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    dieties: {
      heading: `Dieties`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",

      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },

    "sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple": {
      heading: `Sri Anjaneya Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    "sri-maragadabika-manikanta-eswara-swamy-temple": {
      heading: `Sri Maragadabika Manikanta Eswara Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },
    "sri-varada-raja-swamy-temple": {
      heading: `Sri Varada Raja Swamy Temple`,
      description1: (
        <p style={{ textAlign: "center" }}>
          <Sd_comming_soon />
        </p>
      ),
      image: "",
    },

    trusts: {
      heading: `Trusts`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title:
          "Sri Durga Malleswara Swamy Varla Devasthanam Trust | Sri Durga Malleswara Swamy Varla Devasthanam ",
        desc: "Durga Malleswara Swamy Varla Devasthanam Trust comes under Durga Malleswara Swamy Varla Devasthanam to collect funds for preserving the ancient shrine. Contribute to Sri Durga Malleswara Swamy Varla Devasthanam Trust.",
        keywords:
          "Sri Durga Malleswara Swamy Varla Devasthanam Trust, Sri Durga Malleswara Swamy Varla Devasthanam ",
        h1_tag: "Sri Durga Malleswara Swamy Varla Devasthanam Trust",
      },
    },
    "e-hundi": {
      heading: `E Hundi`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
      seo_tag: {
        title:
          "Sri Durga Malleswara Swamy Varla Devasthanam  | Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        desc: "Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam Trust is a nonprofitable organisation. People make donations to SBMSD Trust on Durga Malleswara Swamy Varla Devasthanam Online.",
        keywords:
          "Sri Durga Malleswara Swamy Varla Devasthanam , Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        h1_tag: "Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
      },
    },
    "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust":
      {
        heading: `Saswatha Sri Durga Malleswara Swamy varla Dharma Prachara
      ParishadNithya Annadanam Trust`,
        description1: <Sd_comming_soon />,
        image: "",
      },
    "sri-krishna-nadi-pavithra-harathi-trust": {
      heading: `Sri Krishna Nadi Pavithra Harathi Trust`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },
    "parirakshana-trust": {
      heading: `Parirakshana Trust`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },
    "gosamrakshana-trust": {
      heading: `Gosamrakshana Trust`,
      description1: (
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },

    "do's-&-don'ts": {
      heading: `Do's & Don'ts`,
      description1: (
        <>
          <b>Do’s in Durga Malleswara Swamy Varla Devasthanam:</b> <br />
          - Do Pray for Goddess before you start for Vijayawada.
          <br />
          - Do keep your mind fresh and leave all your anger, Jealous and keep
          all your stress and pains behind, Leave everything on the Goddess
          Ammavaru.
          <br />
          - Do plan your travel and accommodation at Vijayawada city well in
          advance. <br />
          - Do bath in the Krishna River and worship Goddess Durga Ammavaru &
          Lord Malleswara Swamy.
          <br />
          - Take a bath and wear clean clothes before you enter the Temple.
          <br />
          - Do open your eyes while having Darshanam of Ammavaru. <br />
          - Do observe absolute silence and chant “Jai Durga Bhavani” inside the
          Temple.
          <br />
          - Do visit Malleswara Swamy Temple without fail as this temple is
          Durga Malleswara Swamy Temple. <br />
          - Do respect ancient customs and usages while at Vijayawada. <br />
          - Do respect and promote religious sentiments among co-pilgrims.
          <br />
          - Do deposit your offerings & Kanukas in the Hundi Only.
          <br />- Do wear a tilak in accordance with your religious affiliation.{" "}
          <br />
          - Do follow the procedures of the temple and wait for your turn for
          the Darshanam of the Goddess Ammavaru & Lord Siva.
          <br />
          - Do Switch off Cell phones before entering into the Temple.
          <br />
          - Do keep the Temple premises Clean & Hygienic.
          <br />
          - Do contact the Information center for unknown information about
          Devasthanam.
          <br />
          - Do watch your valuables very carefully (i.e Money purses, Gold
          Articles, Luggage bags etc.) as there is fear of theft.
          <br />
          <br />
          <b>Don’ts in Durga Malleswara Swamy Varla Devasthanam: </b>
          <br />
          - Don’t carry much Jewelry and Cash with you. - Don’t wear footwear in
          and around the premises of the Temple.
          <br />
          - Don’t approach touts for Darshanam & Accommodation.
          <br />
          - Don’t approach touts for quick Darshanam.
          <br />
          - Don’t prostrate (Sastanga danda pranamam) inside the Temple.
          <br />
          - Don’t throw away the prasadam and theertham given to you at the
          Temple.
          <br />
          - Don’t enter the shrine naked or wearing only a loin-cloth.
          <br />
          - Don’t carry any weapon inside the Temple.
          <br />
          - Don’t eat non-vegetarian food.
          <br />
          - Don’t consume liquor or other intoxicants.
          <br />
          - Don’t smoke in the Indrakeeladri Hills.
          <br />
          - Don’t wear any head guards like helmets, caps, turbans and hats
          inside the temple premises.
          <br />
          - Don’t buy fake Prasadams from street vendors.
          <br />
          - Don’t commit any act of violence or harshness, anger, jealousy in
          the Temple premises.
          <br />
          - Don’t quarrel in the Temple premises.
          <br />
          - Don’t rush in for Darshanam but take your chance in the queue.
          <br />
          - Don’t enter the Temple; if according to custom or usage, you are
          prohibited to enter.
          <br />
          - Don’t encourage beggary in and around Temple premises.
          <br />
          - Don’t split or be a nuisance inside the Temple premises.
          <br />
          - Don’t carry luggage bags and other belongings inside Temple
          premises. Please deposit in the Cloakrooms of Devasthanam.
          <br />
          - Don’t buy small pieces of cloth as Saree from vendors at uphill for
          offering to Ammavaru.
          <br />
        </>
      ),
      image: "",
    },
    "intellectual-property": {
      heading: `Intellectual Property`,
      description1: (
        <>
          The materials on this site and any other World Wide Web site/Mobile
          Application owned, operated, licensed or controlled by Durga
          Malleswara Swamy Varla Devasthanam or any of its related, affiliated
          or subsidiary companies, may not be copied, distributed, republished,
          uploaded, posted, linked or transmitted in any way without the prior
          written consent of Durga Malleswara Swamy Varla Devasthanam. <br />
          All material(Logos, trademarks, GI, etc) are protected under
          copyright, trademark and other intellectual property laws.
          Modification or use of the materials for any other purpose than
          personal/non-commercial use violates Durga Malleswara Swamy Varla
          Devasthanam’s intellectual property rights.
        </>
      ),
      image: intellectual,
    },
    certifications: {
      heading: `Certifications`,
      description1: (
        <>
          Durga Malleswara Swamy Varla Devasthanam complies in accordance with
          aagamas or Yogic way of providing spiritual assistance. The quality
          services, environmental friendly affairs, security and strict
          adherence to rituals qualify for certifications
        </>
      ),
      image: SD_gallery_08,
    },
    chairman: {
      heading: `A Word With You`,
      description1: (
        // <div style={{ display: "flex" }} className="general-flex">
        //   <div style={{ textAlign: "center" }}>
        //     <img src={chairman} height="200px" />
        //     <h4
        //       style={{
        //         padding: "8px 0px",
        //         color: "#991403",
        //         fontWeight: "600",
        //       }}
        //     >
        //       Mohan reddy
        //     </h4>
        //     <h5 style={{ marginBottom: "5px" }}>Chairman</h5>
        //     <h5>Board of Trustees</h5>
        //   </div>
        //   <div style={{ paddingLeft: "20px" }}>
        //     <h3 style={{ fontStyle: "italic" }}>Namaskaram…</h3>
        //     <br />
        //     As more than 3 to 4 thousand devotees on average days and 7 to 10
        //     thousand on peak days like Sundays, Mondays etc. On average they
        //     visit the holy shrine of Kanipakam. The Devasthanam organizes
        //     efficient systems in providing various facilities to pilgrims. The
        //     aim of the Devasthanam is always improving these amenities.
        //     <br />
        //     <br />
        //     To propagate the Hindu Dharma and Bhakti Cult among the public and
        //     devotees in devotees, the Devathanam has taken up various Dharma
        //     Pracharam Programmes.
        //     <br />
        //     <br />
        //     The major amenities for devotees are RO Water supply, Que- complex,
        //     Dormitories, Choultries, Cottages for accommodation, etc. The
        //     Devasthanam requests the Devotees to come forward and make liberal
        //     donations for divine causes and obtain the blessings of Swayambhu
        //     Sri Varasiddhi Vinayaka Swamy. The donations can be made through
        //     Cheque/Demand Draft in favor of The Executive Officer, Swayambhu Sri
        //     Varasiddhi Vinayaka Swamy vari Devasthanam, Kanipakam. The donations
        //     can be made through online also. The donations given to this
        //     Devasthanam are exempt from the Income tax under section 80 G of the
        //     Income Tax Act- 1962.
        //     <br />
        //     <br />
        //     May Sri Varasiddhi Vinayaka Swamy of Sri Kanipakam showers their
        //     choicest blessings for your long life.
        //     <br />
        //     <br />
        //     <b>
        //       Chairman <br /> Board of trustees
        //     </b>
        //   </div>
        // </div>
        <>
          <Sd_comming_soon />
        </>
      ),
      image: "",
    },
    executive: {
      heading: `A Word With Devotee`,
      description1: (
        <div style={{ display: "flex" }} className="general-flex">
          <div style={{ textAlign: "center" }}>
            <img src={executive} height="200px" />
            <h4
              style={{
                padding: "8px 0px",
                color: "#991403",
                fontWeight: "600",
              }}
            >
              K. Ramachandra Mohan
            </h4>
            {/* <h5 style={{ marginBottom: "5px" }}>B.Com, B.L,</h5> */}
            <h5> Executive Officer</h5>
            {/* <h5>&</h5>
            <h5>Regional Executive Commissioner</h5> */}
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <h3 style={{ fontStyle: "italic" }}>Namaskaram…</h3>
            <br />
            The temple of Kanaka Durga the Goddess of power, riches and
            benevolence and the presiding deity of Vijayawada, is thronged by
            lakhs of pilgrims for worship during the “Navarathri” festival which
            is celebrated with Religious fervor, pomp and festivity. The ancient
            temple of Kanaka Durga, atop the Indrakeeladri hill on the banks of
            the Sacred river Krishna in Vijayawada, abounds with legends of
            historical interest. Indrakeeladri is unique in that it has been the
            chosen abode of Kanaka Durga and Malleswara as Swayambhu (the
            self-existent one). Here Durga is on the right side of Malleswara as
            against the tradition of Goddesses taking their position on the left
            of their consorts. This shows that Shakthi is predominant on the
            Indrakeeladri.
            <br />
            <br />
            For the improvements in amenities at the Temple and surrounding
            premises, devotees are requested to donate for the divine cause
            through Cheque/Demand Draft in favor of The Executive Officer,
            SDMSD, Vijayawada. Right now, the major amenities for devotees are
            RO Water supply, Queue - complex, Dormitories, Choultries, Cottages
            for accommodation, etc. The donations can be made online through our
            website www.kanakadurgamma.org.
            <br />
            <br />
            The donations given to this Devasthanam are exempt from the Income
            tax under section 80 G of the Income Tax Act- 1962.
            <br />
            <br />
            May Sri Durga sametha Malleswara Swamy showers the blessings for the
            devotees for a healthy and wealthy life.
            <br />
            <br />
            <b>The Executive Officer</b>
          </div>
        </div>
      ),
      image: "",
    },
    "adopted-temples": {
      heading: `Adopted Temples`,
      description1: (
        <div className="trust-board-wrapper">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
            <div
              className="sd-seva-booking"
              style={{ justifyContent: "space-between" }}
            >
              {temples?.map((item, i) => {
                return (
                  <div
                    className="seva-div-booking"
                    style={{ padding: "15px", alignItems: "center" }}
                    key={i + "booking-ps"}
                  >
                    <img src={SD_Logo_circle} height="30px" width={"30px"} />
                    <h4 style={{ fontSize: "18px" }}>{item?.name}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ),
      image: "",
    },
    "trust-board": {
      heading: `Board of Trustees`,
      description1: <Sd_comming_soon />,
      // description1: (
      //   <div className="trust-board-wrapper">
      //     <div style={{ width: "100%" }}>
      //       <div className="profile-pic" style={{ textAlign: "center" }}>
      //         <img src={chairman} height="200px" />
      //         <h4
      //           style={{
      //             padding: "8px 0px",
      //             color: "#991403",
      //             fontWeight: "600",
      //           }}
      //         >
      //           Mohan reddy
      //         </h4>
      //         <h5 style={{ marginBottom: "5px" }}>Chairman</h5>
      //       </div>
      //     </div>
      //     <div className="flexed">
      //       {[
      //         { image: user2, name: "K Gopi", position: "Member" },
      //         { image: user3, name: "P Govardhan", position: "Member" },
      //         { image: user4, name: "V Susila", position: "Member" },
      //         { image: user5, name: "D Kanthamma", position: "Member" },
      //         {
      //           image: user6,
      //           name: "M Narasimhulu Chetty",
      //           position: "Member",
      //         },
      //         { image: user7, name: "A Prathima", position: "Member" },
      //         { image: userborder, name: "T Lakshmamma", position: "Member" },
      //         { image: user9, name: "K Marutheswar Rao", position: "Member" },
      //         {
      //           image: userborder,
      //           name: "A Subbalakshumma",
      //           position: "Member",
      //         },
      //         { image: userborder, name: "G Kondaiah", position: "Member" },
      //         {
      //           image: user12,
      //           name: "Smt G Venkata Ramanamma",
      //           position: "Member",
      //         },
      //         { image: user13, name: "J Ramsubbamma", position: "Member" },
      //         { image: userborder, name: "B Anitha", position: "Member" },
      //         {
      //           image: user15,
      //           name: "Sri N Somasekhar, Upa Pradhana Archaka Ex-Officio",
      //           position: "Member",
      //         },
      //       ].map((item, i) => {
      //         return (
      //           <div key={"board" + i} className="trust-board">
      //             <img src={item?.image} height="200px" />
      //             <h4
      //               style={{
      //                 padding: "8px 0px",
      //                 color: "#991403",
      //                 fontWeight: "600",
      //               }}
      //             >
      //               {item.name}
      //             </h4>
      //             <h5> {item.position}</h5>
      //           </div>
      //         );
      //       })}
      //     </div>
      //   </div>
      // ),
      image: "",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {data[type]?.seo_tag?.title ??
            "Durga Malleswara Swamy Varla Devasthanam Official Website"}
        </title>
        <meta
          name="description"
          content={
            data[type]?.seo_tag?.desc ??
            "Durga Malleswara Swamy Varla Devasthanam Official Website"
          }
        />
        <meta name="keywords" content={data[type]?.seo_tag?.keywords} />
      </Helmet>
      <div className="sd-about-temple-history">
        {visible && (
          <div className="sd-about-temple-history-left">
            <ol>
              <li
                className={type === "visiting-places" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(false);
                  setDrop1(!drop1);
                }}
              >
                Visiting Places
                {drop1 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setDrop1(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{ marginTop: "6px" }}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop1(true);
                    }}
                  />
                )}
              </li>
              <div className={drop1 ? "drop1 block" : "drop1 none"}>
                {/* <div
                  className={type === "dieties" ? "main sd-active" : "main"}
                  onClick={() => {
                    setDrop2(!drop2);
                    // setType("dieties");
                  }}
                >
                  <span>Dieties</span>
                  {drop2 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{ marginTop: "6px" }}
                      onClick={() => {
                        setDrop2(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{ marginTop: "6px" }}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop2(true);
                      }}
                    />
                  )}
                </div> */}
                <div className={drop2 ? "drop2 block" : "drop2 none"}>
                  <div
                    className={
                      type ===
                        "sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple" &&
                      "sd-active"
                    }
                    onClick={() => {
                      setType(
                        "sri-anjaneya-swamy-temple-manikanta-eswara-swamy-temple"
                      );
                    }}
                  >
                    Sri Anjaneya Swamy Temple
                  </div>
                  <div
                    className={
                      type ===
                        "sri-maragadabika-manikanta-eswara-swamy-temple" &&
                      "sd-active"
                    }
                    onClick={() => {
                      setType("sri-maragadabika-manikanta-eswara-swamy-temple");
                    }}
                  >
                    Sri Maragadabika Manikanta Eswara Swamy Temple{" "}
                  </div>
                  <div
                    className={
                      type === "sri-varada-raja-swamy-temple" && "sd-active"
                    }
                    onClick={() => {
                      setType("sri-varada-raja-swamy-temple");
                    }}
                  >
                    Sri Varada Raja Swamy Temple
                  </div>
                </div>
              </div>
              <li
                className={type === "adopted-temples" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("adopted-temples");
                }}
              >
                Adopted Temples
              </li>
              <li
                className={type === "trusts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(!drop6);
                }}
              >
                <span> Trusts</span>{" "}
                {drop6 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{ marginTop: "6px" }}
                    onClick={() => {
                      setDrop6(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{ marginTop: "6px" }}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop6(true);
                    }}
                  />
                )}
              </li>

              <div className={drop6 ? "drop6 block" : "drop6 none"}>
                <div
                  className={type === "e-hundi" && "sd-active"}
                  onClick={() => {
                    setType("e-hundi");
                  }}
                >
                  e-Hundi
                </div>
                <div
                  className={
                    type ===
                      "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust" &&
                    "sd-active"
                  }
                  onClick={() => {
                    setType(
                      "saswatha-sri-durga-malleswara-swamy-varla-dharma-prachara-parishadNithya-annadanam-trust"
                    );
                  }}
                >
                  Saswatha Sri Durga Malleswara Swamy varla Dharma Prachara
                  ParishadNithya Annadanam Trust
                </div>
                <div
                  className={
                    type === "sri-krishna-nadi-pavithra-harathi-trust" &&
                    "sd-active"
                  }
                  onClick={() => {
                    setType("sri-krishna-nadi-pavithra-harathi-trust");
                  }}
                >
                  Sri Krishna Nadi Pavithra Harathi Trust
                </div>
                <div
                  className={type === "parirakshana-trust" && "sd-active"}
                  onClick={() => {
                    setType("parirakshana-trust");
                  }}
                >
                  Parirakshana Trust
                </div>
                <div
                  className={type === "gosamrakshana-trust" && "sd-active"}
                  onClick={() => {
                    setType("gosamrakshana-trust");
                  }}
                >
                  Gosamrakshana Trust
                </div>
              </div>

              <li
                className={type === "executive" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("executive");
                }}
              >
                Executive Officer
              </li>
              <li
                className={type === "do's-&-don'ts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("do's-&-don'ts");
                }}
              >
                Do's & Don'ts{" "}
              </li>
            </ol>
          </div>
        )}{" "}
        <div className="sd-about-temple-history-right">
          <div style={{ display: imageLoading ? "none" : "block" }}>
            <div className="sd-about-temple-history-right-top" ref={titleRef}>
              <h1>
                <span> {data[type]?.heading}</span> {}
              </h1>
              <img
                src={SD_divider}
                style={{ height: "20px" }}
                alt={"divider"}
              ></img>
            </div>
            <div className="sd-about-temple-history-right-bottom">
              <div className="sd-athrb-top">
                {data[type]?.image !== "" ? (
                  <img
                    src={data[type]?.image}
                    alt="alternative"
                    align="left"
                    className="sd-imgs"
                    onLoadStart={() => {
                      setImageLoading(true);
                    }}
                    onLoad={() => {
                      setImageLoading(false);
                    }}
                  />
                ) : (
                  ""
                )}
                <p>{data[type]?.description1}</p>
              </div>
              {/* <div className="sd-athrb-bottom">{data[type].description2}</div> */}
            </div>
          </div>
          <div
            style={{
              display: !imageLoading ? "none" : "block",
              height: "30%",
            }}
          >
            <Sd_loading />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Sd_about_general_information_component);
